body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li {
  margin: 0px;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
