.menu {
  width: 85%;
  margin: 40px auto;
  &__titulo {
    font-size: 40px;
    font-weight: 600;
    color: #f88c91;
    font-family: "Script MT Bold", cursive;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  &__carrusel {
    width: 85%;
    margin: 10px auto 20px auto;
    @media (max-width: 1000px) {
      width: 85%;
    }
    @media (max-width: 500px) {
      width: 75%;
    }
    &--contenido {
      margin: 0px auto;
      color: black;
      font-family: "Script MT Bold", cursive;
      &__card {
        min-height: 180px;
        border-radius: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        margin-bottom: 20px;
        &--imagen {
          width: 100%;
          border-radius: 10px;
          img {
            width: 100%;
            height: 100px;
            border-radius: 8px 8px 0px 0px;
          }
        }
        &--nombre {
          padding: 0px 20px;
          font-size: 18px;
          font-weight: 600;
          color: #f88c91;
        }
        &--descripcion {
          font-size: 14px;
          padding: 0px 20px;
          line-height: 18px;
        }
        &--boton {
          text-align: center;
          background: #f88c91;
          color: white;
          width: 100%;
          min-height: 30px;
          margin: 10px auto 0px auto;
          font-weight: 600;
          padding-top: 10px;
          @media (max-width: 500px) {
            height: 50px;
          }
          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.contMenu {
  width: 85%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  @media (max-width: 1200px) {
    display: block;
  }
}
