.hero {
  margin-top: 90px;
  width: 100%;
  height: 543px;
  position: relative;
  @media (max-width: 535px) {
    margin-top: 30px;
  }
  @media (max-width: 1950px) {
    height: 810px;
  }
  @media (max-width: 1850px) {
    height: 760px;
  }
  @media (max-width: 1750px) {
    height: 710px;
  }
  @media (max-width: 1650px) {
    height: 660px;
  }
  @media (max-width: 1550px) {
    height: 610px;
  }
  @media (max-width: 1450px) {
    height: 560px;
  }
  @media (max-width: 1250px) {
    height: 510px;
  }
  @media (max-width: 1150px) {
    height: 480px;
  }
  @media (max-width: 1050px) {
    height: 430px;
  }
  @media (max-width: 950px) {
    height: 380px;
  }
  @media (max-width: 850px) {
    height: 330px;
  }
  @media (max-width: 750px) {
    height: 280px;
  }
  @media (max-width: 650px) {
    height: 260px;
  }
  @media (max-width: 550px) {
    height: 220px;
  }
  @media (max-width: 450px) {
    height: 180px;
  }
  @media (max-width: 350px) {
    height: 140px;
  }
  &__banner {
    width: 100%;
    position: absolute;
    img {
      width: 100%;
    }
  }
  &__icono {
    position: absolute;
    top: -100%;
    left: -100%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    animation: slideIn 2s forwards;
    @media (max-width: 785px) {
      top: 10%;
      padding-top: 0px;
    }
    @media (max-width: 850px) {
      top: 20%;
      padding-top: 0px;
    }
    @media (max-width: 920px) {
      top: 20%;
      padding-top: 0px;
    }
    @media (max-width: 475px) {
      top: 10%;
      padding-top: 0px;
    }
    &--texto {
      width: 200px;
      margin: 0px auto;
      padding-top: 50px;
      @media (max-width: 850px) {
        width: 190px;
        padding-top: 40px;
      }
      @media (max-width: 785px) {
        width: 80px;
        padding-top: 0px;
      }
      @media (max-width: 790px) {
        padding-top: 90px;
      }
      @media (max-width: 755px) {
        padding-top: 120px;
      }
      @media (max-width: 535px) {
        width: 110px;
        padding-top: 120px;
      }
      @media (max-width: 475px) {
        width: 70px;
        padding-top: 120px;
      }
      @media (max-width: 280px) {
        width: 50px;
        padding-top: 120px;
      }
      img {
        width: 100%;
      }
    }
    &--descripcion {
      opacity: 0;
      animation: fadeIn 1s 0.5s forwards;
      color: rgb(255, 255, 255);
      font-weight: 700;
      font-size: 40px;
      font-family: "Script MT Bold", cursive;
      @media (max-width: 785px) {
        font-size: 16px;
      }
      @media (max-width: 850px) {
        font-size: 26px;
      }
      @media (max-width: 535px) {
        font-size: 20px;
      }
      @media (max-width: 475px) {
        font-size: 16px;
      }
      @media (max-width: 280px) {
        font-size: 12px;
      }
    }
  }
}

@keyframes slideIn {
  from {
    top: -100%;
    left: 50%;
  }
  to {
    top: 30%;
    left: 50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 785px) {
  @keyframes slideIn {
    from {
      top: -100%;
      left: 50%;
    }
    to {
      top: 23%;
      left: 50%;
    }
  }
}

@media (max-width: 535px) {
  @keyframes slideIn {
    from {
      top: -100%;
      left: 50%;
    }
    to {
      top: 18%;
      left: 50%;
    }
  }
}

@media (max-width: 475px) {
  @keyframes slideIn {
    from {
      top: -100%;
      left: 50%;
    }
    to {
      top: 12%;
      left: 50%;
    }
  }
}

@media (max-width: 280px) {
  @keyframes slideIn {
    from {
      top: -100%;
      left: 50%;
    }
    to {
      top: 8%;
      left: 50%;
    }
  }
}
