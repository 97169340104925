.promociones {
  &__titulo {
    font-size: 40px;
    text-align: center;
    color: #f88c91;
    font-family: "Script MT Bold", cursive;
    margin: 40px auto;
    @media (max-width: 400px) {
      font-size: 30px;
    }
    @media (max-width: 400px) {
      font-size: 25px;
    }
  }
  &__carrusel {
    width: 75%;
    margin: 10px auto 20px auto;
    @media (max-width: 1000px) {
      width: 85%;
    }
    @media (max-width: 500px) {
      width: 75%;
    }
    &--contenido {
      margin: 0px auto;
      color: black;
      text-align: center;
      &__card {
        min-height: 520px;
        border: 1px solid #cbcbcb;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 800px) {
          margin-bottom: 20px;
        }
        &--imagen {
          width: 100%;
          height: 220px;
          img {
            width: 100%;
            height: 220px;
          }
        }
        &--nombre {
          padding: 0px 20px;
          font-size: 26px;
          font-weight: 600;
        }
        &--descripcion {
          font-size: 20px;
          padding: 0px 20px;
        }
        &--precio {
          min-height: 70px;
          font-size: 16px;
          line-height: 18px;
          background: #f88c91;
          color: white;
          &--boton {
            background: rgb(109, 43, 45);
            width: 70%;
            min-height: 30px;
            margin: 10px auto 0px auto;
            font-weight: 600;
            padding-top: 10px;
            &:hover {
              opacity: .8;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

:where(.css-dev-only-do-not-override-1qfezbu).ant-carousel
  .slick-dots
  li.slick-active
  button {
  background: black !important;
}

:where(.css-dev-only-do-not-override-1qfezbu).ant-carousel
  .slick-dots
  li
  button {
  background: black !important;
}

.cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    display: block;
  }
}
