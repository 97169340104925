.footer {
  &__secciones {
    background-color: #111;
    color: white;
    padding-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;
    padding: 50px;
    @media (max-width: 900px) {
      display: block;
    }
    &--columna1 {
      &--imagen {
        width: 100px;
        margin: 0px auto;
        img {
          width: 100%;
        }
      }
      &--descripcion {
        text-align: center;
        font-family: "Script MT Bold", cursive;
      }
    }
    &--columna2 {
      &--ordena {
        height: 74px;
        font-size: 35px;
        padding-top: 20px;
        text-align: center;
        font-family: "Script MT Bold", cursive;
        color: #f88c91;
        @media (max-width: 350px) {
            font-size: 25px;
        }
      }
      &--descripcion {
        text-align: center;
        font-family: "Script MT Bold", cursive;
      }

      &--boton {
        background: #f88c91;
        color: white;
        font-size: 17px;
        text-align: center;
        width: 30%;
        min-height: 30px;
        margin: 0px;
        font-weight: 600;
        padding-top: 10px;
        margin: 10px auto;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
    &--columna3 {
      &--contacto {
        height: 74px;
        font-size: 35px;
        padding-top: 20px;
        text-align: center;
        font-family: "Script MT Bold", cursive;
        color: #f88c91;
        @media (max-width: 350px) {
            font-size: 25px;
        }
      }
      &--descripcion {
        text-align: center;
        font-family: "Script MT Bold", cursive;
      }
      &--redes {
        font-size: 35px;
        width: 30%;
        margin: 10px auto;
        @media (max-width: 1500px) {
          width: 40%;
        }
        @media (max-width: 1000px) {
          width: 70%;
        }
        @media (max-width: 900px) {
          width: 15%;
        }
        span {
          margin-right: 20px;
          &:hover {
            color: #f88c91;
            cursor: pointer;
          }
        }
      }
      &--boton {
        background: #f88c91;
        color: white;
        font-size: 17px;
        text-align: center;
        width: 30%;
        min-height: 30px;
        margin: 0px;
        font-weight: 600;
        padding-top: 10px;
        margin: 10px auto;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
  &__desarrollado {
    background-color: black;
    color: rgb(192, 192, 192);
    text-align: center;
    height: 40px;
    padding-top: 20px;
    font-size: 12px;
  }
}
