.barraNavegacion {
  width: 100%;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 20px;
  height: 70px;
  z-index: 10000;
  @media (max-width: 535px) {
    height: 30px;
  }
  &__icono {
    margin-left: 100px;
    @media (max-width: 820px) {
      margin-left: 50px;
    }
    @media (max-width: 770px) {
      margin-left: 0px;
    }
    img {
      height: 70px;
      @media (max-width: 535px) {
        height: 40px;
      }
    }
  }
  &__lista {
    width: 50%;
    height: 20px;
    margin: 10px auto;
    list-style: none;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 920px) {
      margin: 10px 30px;
    }
    @media (max-width: 785px) {
      margin: 10px 30px;
    }
    @media (max-width: 535px) {
      margin: 0px 30px;
    }
    &--boton {
      width: 100px;
      height: 14px;
      background-color: #f88c91;
      padding: 10px 35px;
      margin-top: 7px;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
      font-family: "Script MT Bold", cursive;
      @media (max-width: 270px) {
        width: 60px;
        padding: 10px 15px;
      }
      @media (max-width: 475px) {
        width: 60px;
        padding: 7px 25px;
      }
      @media (max-width: 535px) {
        margin: 0px 30px;
      }
    }
    &--enlace {
      margin-right: 20px;
      margin-top: 15px;
      @media (max-width: 785px) {
        display: none;
      }
      a {
        transition: all ease 0.8s;
        color: white;
        text-decoration: none;
        font-weight: 600;
        font-family: "Script MT Bold", cursive;
        cursor: pointer;
        &:hover {
          color: #f88c91;
        }
      }
    }
  }
}

.contenedor {
  display: flex;
  @media (max-width: 785px) {
    width: 50%;
    margin: 0px auto;
  }
  @media (max-width: 475px) {
    width: 70%;
    margin: 0px auto;
  }
  @media (max-width: 365px) {
    width: 80%;
    margin: 0px auto;
  }
  @media (max-width: 315px) {
    width: 95%;
    margin: 0px auto;
  }
}