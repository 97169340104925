.experiencia {
  margin-top: 50px;
  &__contenido {
    width: 85%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 800px) {
      display: block;
      width: 75%;
    }
    &--imagen {
      width: 60%;
      margin: 0px auto;
      img {
        width: 100%;
      }
    }
    &--texto {
      margin-top: 30px;
      font-family: "Script MT Bold", cursive;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &--pr1 {
        font-size: 40px;
        font-weight: 600;
        color: #f88c91;
        font-family: "Dancing Script", cursive;
        font-optical-sizing: auto;
        font-style: normal;
      }
      &--pr2 {
        font-size: 20px;
        color: rgb(115, 115, 115);
      }
      &--boton {
        background: #f88c91;
        color: white;
        font-size: 17px;
        text-align: center;
        width: 30%;
        min-height: 30px;
        margin: 0px;
        font-weight: 600;
        padding-top: 10px;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
}
